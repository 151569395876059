@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import 'theme/material.scss';
@import 'theme/spinner.scss';
@import 'theme/robot-font.css';
@import 'theme/font-awesome.css';

html, body, app-root {
  margin: 0;
  width: 100%;
  height: 100%;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.flex {
  display: flex !important;
  flex-direction: column;
}

.flex-row {
  display: flex !important;
  flex-direction: row;
}

.flex-fill {
  flex: 1 1 auto;
}

.mat-drawer-content {
  display: flex !important;
  flex-direction: row;
}

router-outlet + * {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}

.text-right {
  text-align: right;
}

table:not(.mat-calendar-table) {
  border-collapse: collapse;

  th, td {
    padding: 0 10px;
    /*line-height: 48px;*/
    line-height: 18px;
  }

  tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    height: 48px;
  }
}

mat-table.table-striped {
  mat-row:nth-child(2n) {
    background-color: #f6f6f6;
  }
}

table.table-striped {
  tbody > tr:nth-child(2n+1) {
    background-color: #f6f6f6;
  }
}

mat-header-cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

mat-cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.no-width {
  max-width: none !important;
}

.mat-autocomplete-panel {
  min-width : 280px !important;
}

.col-max-w-80 {
  max-width:80px;
}

.col-max-w-120 {
  max-width:120px;
}

.col-max-w-40 {
  max-width:40px;
}

.col-max-w-60 {
  max-width:60px;
}

.col-w-200 {
  width:200px;
}

.col-max-w-110 {
  max-width:110px;
}

.col-no-width {
  width: 100%;
}

.wrap-word {
  word-wrap: break-word;
}

.mat-tooltip {
  font-size: 11px !important;
  line-height: 2em !important;
  white-space: pre-line !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.mat-divider.mat-divider-vertical, .mat-divider.mat-divider-horizontal {
  border-top-color: red !important;
  border-top-width: 1px !important;
  border-bottom-color: red !important;
  border-bottom-width: 1px !important;  
  border-right-width: 1px !important;
  border-right-color: red !important;
  border-left-width: 1px !important;
  border-left-color: red !important;
}

.mat-tab-label {  
  background-color: rgb(244, 67, 54) !important;  
  color: #FFFFFF !important;
  opacity: 1 !important;
}

.mat-tab-label-active {
  background-color: rgb(0, 188, 212) !important;  
}

.mat-card-header-text {
  width: 100% !important;
  margin-left: 0px !important;
}
